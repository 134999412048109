import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { verifyEmailAction } from '@/redux/actions/company';
import { verifyEmail, TVerifyEmailResponse } from '@/services/api/company';
import message from '@/utils/message';

// FUNCTION

export function* verifyEmailSaga(action: ActionType<typeof verifyEmailAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(verifyEmail, materials);
    const verifyEmailResponse: TVerifyEmailResponse = response as TVerifyEmailResponse;
    yield put(verifyEmailAction.success(verifyEmailResponse));
    successCallback?.(verifyEmailResponse);
  } catch (err) {
    message.error('verifyEmailSaga error:', err.data.status_message);
    yield put(verifyEmailAction.failure(err));
    failedCallback?.(err);
  }
}
