import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getListEmployeeInsideAction } from '@/redux/actions/employee';
import { getListEmployeeInside, TGetListEmployeeInsideResponse } from '@/services/api/employee';
import message from '@/utils/message';

// FUNCTION

export function* getListEmployeeInsideSaga(action: ActionType<typeof getListEmployeeInsideAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getListEmployeeInside, materials);
    const getListEmployeeInsideResponse: TGetListEmployeeInsideResponse = response as TGetListEmployeeInsideResponse;
    yield put(getListEmployeeInsideAction.success(getListEmployeeInsideResponse));
    successCallback?.(getListEmployeeInsideResponse);
  } catch (err) {
    message.error('getListEmployeeInsideSaga error:', err.data.status_message);
    yield put(getListEmployeeInsideAction.failure(err));
    failedCallback?.(err);
  }
}
