import { createReducer } from 'deox';

import {
  TAuthLoginResponse,
  TAuthLogoutResponse,
  TAuthSignUpResponse,
  TSendMailResponse,
  TResetPasswordResponse,
  TChangePasswordResponse,
  TUpdateProfileResponse,
  TUpdateUserResponse,
  TGetUserInfoResponse,
} from '@/services/api/auth';
import {
  authLoginAction,
  authLogoutAction,
  authSignUpAction,
  sendMailAction,
  resetPasswordAction,
  changePasswordAction,
  updateProfileAction,
  updateUserAction,
  getUserInfoAction,
} from '@/redux/actions';
import { authLoginUpdateState } from './auth-login';
import { authLogoutUpdateState } from './auth-logout';
import { authSignupUpdateState } from './signup';
import { sendMailUpdateState } from './send-mail';
import { resetPasswordUpdateState } from './reset-password';
import { changePasswordUpdateState } from './change-password';
import { updateProfileUpdateState } from './update-profile';
import { updateUserUpdateState } from './update-user';
import { getUserInfoUpdateState } from './get-user-info';
import AuthHelpers from '@/services/helpers';

export type TAuthState = {
  authLoginResponse?: TAuthLoginResponse;
  authLogoutResponse?: TAuthLogoutResponse;
  authSignUpResponse?: TAuthSignUpResponse;
  sendMailResponse?: TSendMailResponse;
  resetPasswordResponse?: TResetPasswordResponse;
  changePasswordResponse?: TChangePasswordResponse;
  updateProfileResponse?: TUpdateProfileResponse;
  updateUserResponse?: TUpdateUserResponse;
  getUserInfoResponse?: TGetUserInfoResponse;
};

const initialState: TAuthState = {
  authLoginResponse: undefined,
  authLogoutResponse: undefined,
  authSignUpResponse: undefined,
  sendMailResponse: undefined,
  resetPasswordResponse: undefined,
  changePasswordResponse: undefined,
  updateProfileResponse: undefined,
  updateUserResponse: undefined,
  getUserInfoResponse: {
    'user_id': null,
    'fullName': 'Full Name',
    'username': 'username',
    'email': '',
    'role': AuthHelpers.getUserInfo()?.role || '',
    'companyInfo': {
      'companyID': null,
      'companyName': '',
      'companyLocation': '',
      'companyStreet': '',
      'companyPostalCode': '',
      'companyPhone': '',
      'companyFax': '',
      'companyEmail': '',
      'companyLogo': '',
    },
  },
};

const AuthReducer = createReducer(initialState, (handleAction) => [
  handleAction(authLoginAction.success, authLoginUpdateState),
  handleAction(authLogoutAction.success, authLogoutUpdateState),
  handleAction(authSignUpAction.success, authSignupUpdateState),
  handleAction(sendMailAction.success, sendMailUpdateState),
  handleAction(resetPasswordAction.success, resetPasswordUpdateState),
  handleAction(changePasswordAction.success, changePasswordUpdateState),
  handleAction(updateProfileAction.success, updateProfileUpdateState),
  handleAction(updateUserAction.success, updateUserUpdateState),
  handleAction(getUserInfoAction.success, getUserInfoUpdateState),
]);

export default AuthReducer;
