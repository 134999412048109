import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TGetListProjectParams = unknown;

export type TGetListProjectResponse = TCommonResponse;

export type TGetListProjectMaterials = {
  employee_id?: TGetListProjectParams; // Correct the property name to "employee_id"
  company_id?: TGetListProjectParams; // Correct the property name to "company_id"
};

export const getListProject = async (params): Promise<TGetListProjectResponse> => {
  try {
    const response = await ApiService.get(
      `/projects/api/listProjectsByEmployee/?employee_id=${params.employee_id}&company_id=${params.company_id}`,
    );
    return response?.data; // Return the data from the response
  } catch (error) {
    message.error(error.response.data.status_message);
    throw error.response; // Rethrow the error to handle it in your saga
  }
};
