import { message as messageAntd } from 'antd';

const message = {
  error: (...args: any[]): void => {
    const messageContent = args.map((arg) => arg.toString()).join(' ');
    if (!messageContent.includes('Ungültiges Zugriffstoken')) {
      messageAntd.error(args);
    }
  },

  success: messageAntd.success,
  warning: messageAntd.warning,
  info: messageAntd.info,
};

export default message;
