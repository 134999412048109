import React, { lazy, Suspense, useEffect } from 'react';
import { Redirect, RouteComponentProps, useLocation } from '@reach/router';

import Helpers from '@/services/helpers';
import Loading from '@/components/Loading';
import { getUserInfoAction, TGetUserInfoRequest } from '@/redux/actions';
import message from '@/utils/message';
import { useDispatch, useSelector } from 'react-redux';
import AuthHelpers from '@/services/helpers';

const retryLoadComponent = (fn: () => Promise<unknown>, retriesLeft = 5, interval = 1000): any =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          retryLoadComponent(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });

const Home = lazy(() => retryLoadComponent(() => import('@/pages/Home')));
const Login = lazy(() => retryLoadComponent(() => import('@/pages/Login')));
const Project = lazy(() => retryLoadComponent(() => import('@/pages/Project')));
const Account = lazy(() => retryLoadComponent(() => import('@/pages/Account')));
// const ProjectDetail = lazy(() => retryLoadComponent(() => import('@/pages/ProjectDetail')));

export const LayoutPaths = {
  Guest: '/',
  Auth: '/',
  Project: '/project',
  Admin: '/admin',
};

export const ModulePaths = {};

export const Paths = {
  Home: '/',
  Login: '/login',
  Project: '/project',
  Account: '/account',
  Rest: '*',
};

export const Pages = {
  Home,
  Login,
  Project,
  Account,
};

interface IRouteProps extends RouteComponentProps {
  component: any;
}

export const AuthRoute: React.FC<IRouteProps> = ({ component: Component, ...rest }) => {
  const loggedIn: string | any = Helpers.getAccessToken();

  return loggedIn ? (
    <Redirect noThrow from={Paths.Rest} to={LayoutPaths.Admin} />
  ) : (
    <Suspense fallback={<Loading />}>
      <Component {...rest} />
    </Suspense>
  );
};

const RouteWrapper = ({ component: Component, ...rest }): any => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    // Actions to perform on route change
    try {
      dispatch(
        getUserInfoAction.request((response: any): any => {
          if (response?.status_message === 'success') {
            if (response?.data) {
              dispatch(getUserInfoAction.success(response.data));
              AuthHelpers.storeUserInfo(response.data);
            }
          }
        }),
      );
    } catch (error) {
      message.error('Failed to get user info. Please try again later.', error.data.status_message);
    }
  }, [dispatch, location]);

  return <Component location={location} {...rest} />;
};

export const ProtectedRoute: React.FC<IRouteProps> = ({ component: Component, ...rest }) => {
  const loggedIn: string | any = Helpers.getAccessToken() != undefined || Helpers.getAccessToken() != null;
  const userRole = useSelector((state: any) => state.authReducer.getUserInfoResponse?.role);

  // // Redirect 'Guest' users from certain pages
  // if (userRole === 'Guest' && [Paths.Account].includes(rest.path)) {
  //   return <Redirect noThrow from={Paths.Rest} to={LayoutPaths.Project} />;
  // }
  if (!loggedIn) {
    return <Redirect noThrow from={Paths.Rest} to={Paths.Login} />; // Chuyển hướng tới trang đăng nhập nếu không có token
  }

  return (
    <Suspense fallback={<Loading />}>
      <RouteWrapper {...rest} component={Component} />
    </Suspense>
  );
};

export const PublicRoute: React.FC<IRouteProps> = ({ component: Component, ...rest }) => {
  const loggedIn: string | any = Helpers.getAccessToken() != undefined || Helpers.getAccessToken() != null;
  return loggedIn ? (
    <Redirect noThrow from={Paths.Rest} to={LayoutPaths.Project} />
  ) : (
    <Suspense fallback={<Loading />}>
      <Component {...rest} />
    </Suspense>
  );
};
