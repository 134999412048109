import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getListCountryAction } from '@/redux/actions/location';
import { getListCountry, TGetListCountryResponse } from '@/services/api/location';
import message from '@/utils/message';

// FUNCTION

export function* getListCountrySaga(action: ActionType<typeof getListCountryAction.request>): Generator {
  const { successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getListCountry);
    const getListCountryResponse: TGetListCountryResponse = response as TGetListCountryResponse;
    yield put(getListCountryAction.success(getListCountryResponse));
    successCallback?.(getListCountryResponse);
  } catch (err) {
    message.error('getListCountrySaga error:', err.data.status_message);
    yield put(getListCountryAction.failure(err));
    failedCallback?.(err);
  }
}
