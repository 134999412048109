import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TUpdateUserParams = unknown;

export type TUpdateUserMaterials = {
  dataSubmit?: TUpdateUserParams;
};

export type TUpdateUserResponse = TCommonResponse;

export const updateUser = async (dataSubmit: TUpdateUserMaterials): Promise<TUpdateUserResponse> => {
  try {
    const data = await dataSubmit;
    const response = await ApiService.post(`/users/api/updateUserInfo/`, data);
    return response?.data;
  } catch (error) {
    message.error('API Error:', error.data.status_message);
  }
};
