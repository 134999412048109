import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TAssignProjectListParams = unknown;

export type TAssignProjectListResponse = TCommonResponse;

export type TAssignProjectListMaterials = {
  employee_id?: TAssignProjectListParams;
  company_id?: TAssignProjectListParams;
  unassign?: TAssignProjectListParams;
  arrayList?: TAssignProjectListParams;
};

export const assignProjectList = async ({
  employee_id,
  company_id,
  unassign,
  arrayList,
}: TAssignProjectListMaterials): Promise<TAssignProjectListResponse> => {
  try {
    const params = {
      'id': employee_id,
      'company_id': company_id,
      'unassign': unassign,
      'project_ids': arrayList,
    };
    const response = await ApiService.post('/projects/api/assignProjectsToEmployee/', params);
    return response?.data; // Return the data from the response
  } catch (error) {
    message.error('API Error:', error.data.status_message);
  }
};
