import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TProjectExportPdfParams = unknown;

export type TProjectExportPdfResponse = TCommonResponse;

export type TProjectExportPdfMaterials = {
  project_id?: TProjectExportPdfParams; // Correct the property name to "project_id"
};

export const projectExportPdf = async (id): Promise<TProjectExportPdfResponse> => {
  try {
    const response = await ApiService.get(`/projects/api/getProjectPDFFile/?project_id=${id.project_id}`);
    return response?.data; // Return the data from the response
  } catch (error) {
    message.error(error.response.data.status_message);
    throw error.response; // Rethrow the error to handle it in your saga
  }
};
