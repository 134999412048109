import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { upgradeLicenseAction } from '@/redux/actions/company';
import { upgradeLicense, TUpgradeLicenseResponse } from '@/services/api/company';
import message from '@/utils/message';

// FUNCTION

export function* upgradeLicenseSaga(action: ActionType<typeof upgradeLicenseAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(upgradeLicense, materials);
    const upgradeLicenseResponse: TUpgradeLicenseResponse = response as TUpgradeLicenseResponse;
    yield put(upgradeLicenseAction.success(upgradeLicenseResponse));
    successCallback?.(upgradeLicenseResponse);
  } catch (err) {
    message.error('upgradeLicenseSaga error:', err.data.status_message);
    yield put(upgradeLicenseAction.failure(err));
    failedCallback?.(err);
  }
}
