import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TUpdateProfileParams = unknown;

export type TUpdateProfileMaterials = {
  dataSubmit?: any;
};

export type TUpdateProfileResponse = TCommonResponse;

export const updateProfile = async (dataSubmit: TUpdateProfileMaterials): Promise<TUpdateProfileResponse> => {
  try {
    const data = await dataSubmit;
    const response = await ApiService.post(`/users/api/updateCompanyInfo/`, data);
    return response?.data;
  } catch (error) {
    message.error('API Error:', error.data.status_message);
  }
};
