import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { changePasswordAction } from '@/redux/actions/auth';
import { changePassword, TChangePasswordResponse } from '@/services/api/auth';
import message from '@/utils/message';
import { t } from 'i18next';

// FUNCTION

export function* changePasswordSaga(action: ActionType<typeof changePasswordAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(changePassword, materials);
    const changePasswordResponse: TChangePasswordResponse = response as TChangePasswordResponse;
    yield put(changePasswordAction.success(changePasswordResponse));
    successCallback?.(changePasswordResponse);
  } catch (err) {
    // message.error('changePasswordSaga error:', err.data.status_message);
    if (err?.status_message == undefined) {
      message.error(t('Error.wrongOldPassword'));
    }
    yield put(changePasswordAction.failure(err));
    failedCallback?.(err);
  }
}
