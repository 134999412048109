import { TAuthState } from '@/redux/reducers/auth';
import { TGetUserInfoSuccess } from '@/redux/actions/auth';

export const getUserInfoUpdateState = (state: TAuthState, action): TAuthState => {
  const updatedState = {
    ...state,
    getUserInfoResponse: action.payload.response,
  };
  return updatedState;
};
