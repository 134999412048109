export const stepsMainEN = (): any => [
  {
    name: 'Main-en',
    selector: '#btn-setting',
    content: 'Click Settings.',
    position: 'left',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('right');
        reactour[i].classList.add('left');
      }
      // Simulate a click to open the modal if it is not already open
    },
  },
  {
    selector: '.Work-Tabs [data-node-key="tab1"]',
    content: 'Click on External Load Capacity.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('left');
        reactour[i].classList.remove('top');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#Pname',
    content: 'Select a number for the load point.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#pfahlTyp',
    content: 'Specify the pile type.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#PfahlAnzahl',
    content: 'Enter the planned number of piles at the load point.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#PfahlAchsAbstandxD',
    content: 'Enter the pile center distance (measured in the floor plan) as a multiple of the pile diameter, e.g. 3.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#AEHoehe',
    content:
      'Specify the global elevation of the drill plane as a value above Normal Zero. For additional information, move your mouse cursor to the column heading and leave it there for a few seconds.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#SollPfahlOberKante',
    content:
      'Specify the global height of the planned top of the pile as a value above Normal Zero. For additional information, move your mouse cursor to the column heading and leave it there for a few seconds.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#Rechtswert',
    content:
      'Specify the coordinate of the load point in the floor plan in the X direction. For additional information, move your mouse cursor to the column heading and leave it there for a few seconds.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#Hochwert',
    content:
      'Specify the coordinate of the load point in the floor plan in the Y direction. For additional information, move your mouse cursor to the column heading and leave it there for a few seconds.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#SollDurchmesser',
    content: 'Enter the planned diameter or, for square piles, the planned edge length.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#AlternativeCharakteristischeLastZ',
    content: 'Specify the vertical characteristic load acting at the load point. Positive values = pressure.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#AlternativeDesignLastZ',
    content: 'Specify the vertical design load acting at the load point. Positive values = pressure.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#BodenProfil',
    content:
      'Select the soil profile to be considered at the load point. To do this, you must have created one or more soil profiles in the soil profile table.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#einzelMindestEindringung',
    content:
      'This value indicates how far the pile must penetrate at least into a penetration-relevant soil layer. The penetration relevance is defined in the soil profile table.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#einzelzulaessigeSetzungCm',
    content:
      'Enter the allowable settlement for this load point. If a global value has been set, the larger of the two will be used.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#prozentualerMantelAnteil',
    content:
      'Here you can enter the percentage of skin friction that should be activated for the calculation or the percentage that is activated via the relative displacement between the pile',
    position: 'left',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('right');
        reactour[i].classList.add('left');
      }
    },
  },
  {
    selector: '.Work-Tabs [data-node-key="tab2"]',
    content: 'Click on soil profile.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
];

export const stepsMainDE = (): any => [
  {
    name: 'Main-de',
    selector: '#btn-setting',
    content: 'Klicken Sie auf Einstellungen.',
    position: 'left',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('right');
        reactour[i].classList.add('left');
      }
      // Simulate a click to open the modal if it is not already open
    },
  },
  {
    selector: '.Work-Tabs [data-node-key="tab1"]',
    content: 'Klicken Sie auf Äußere Tragfähigkeit.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#Pname',
    content: 'Wählen Sie eine Nummer für den Lastpunkt.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#pfahlTyp',
    content: 'Geben Sie den Pfahltyp an.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#PfahlAnzahl',
    content: 'Geben Sie die geplante Pfahlanzahl an dem Lastpunkt an.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#PfahlAchsAbstandxD',
    content:
      'Geben Sie den Pfahl Achsabstand (im Grundriss gemessen) als ein Vielfaches des Pfahldurchmessers an z.B. 3.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#AEHoehe',
    content:
      'Geben Sie die globale Höhe der Bohrebene als Wert über Normal Null an. Für zusätzliche Informationen bewegen Sie Ihren Mauszeiger auf die Spaltenüberschrift und lassen diesen dort einige Sekunden stehen.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#SollPfahlOberKante',
    content:
      'Geben Sie die globale Höhe der geplanten Pfahloberkante als Wert über Normal Null an. Für zusätzliche Informationen bewegen Sie Ihren Mauszeiger auf die Spaltenüberschrift und lassen diesen dort einige Sekunden stehen.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#Rechtswert',
    content:
      'Geben Sie die Koordinate des Lastpunkts im Grundriss in X-Richtung an. Für zusätzliche Informationen bewegen Sie Ihren Mauszeiger auf die Spaltenüberschrift und lassen diesen dort einige Sekunden stehen.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#Hochwert',
    content:
      'Geben Sie die Koordinate des Lastpunkts im Grundriss in Y-Richtung an. Für zusätzliche Informationen bewegen Sie Ihren Mauszeiger auf die Spaltenüberschrift und lassen diesen dort einige Sekunden stehen.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#SollDurchmesser',
    content: 'Geben Sie den geplanten Durchmesser bzw. für quadratische Pfähle die geplante Kantenlänge an.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#AlternativeCharakteristischeLastZ',
    content: 'Geben Sie die am Lastpunkt wirkende vertikale charakteristische Last an. Positive Werte = Druck.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#AlternativeDesignLastZ',
    content: 'Geben Sie die am Lastpunkt wirkende vertikale design Last an. Positive Werte = Druck.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#BodenProfil',
    content:
      'Wählen Sie das Bodenprofil aus, das am Lastpunkt berücksichtigt werden soll. Dazu müssen Sie in der Bodenprofiltabelle ein oder mehrere Bodenprofile erstellt haben.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#einzelMindestEindringung',
    content:
      'Dieser Wert gibt an wie weit der Pfahl mindestens in eine eindringungsrelevante Bodenschicht eindringen muss. Die Eindringungsrelevanz wird in der Bodenprofil Tabelle definiert.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#einzelzulaessigeSetzungCm',
    content:
      'Geben Sie die zulässige Setzung für diesen Lastpunkt an. Wenn ein globaler Wert eingestellt wurde, so wird der Größere der Beiden verwendet',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
  {
    selector: '.tour#prozentualerMantelAnteil',
    content:
      'Hier können Sie den prozentualen Anteil der Mantelreibung eigeben, welcher für den Berechnung aktiviert werden soll bzw. der Anteil, welcher ber der Relativverschiebung zwischen Pfahl aktiviert wird',
    position: 'left',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('right');
        reactour[i].classList.add('left');
      }
    },
  },
  {
    selector: '.Work-Tabs [data-node-key="tab2"]',
    content: 'Klicken Sie auf Bodenprofil.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
      }
    },
  },
];
