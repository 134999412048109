import cookie from 'react-cookies';
import env from '@/env';

const COOKIE_DOMAIN = env.cookie.domain;
const COOKIE_ACCESS_TOKEN = `atk`;
const COOKIE_REFRESH_TOKEN = `rtk`;
const COOKIE_USER_INFO = `user_info`;
const cookieSetting = {
  path: '/',
  domain: COOKIE_DOMAIN,
};

const setCookie = (name: string, value: string): void => cookie.save(name, value, cookieSetting);
const getCookie = (name: string): string => cookie.load(name);
const removeCookie = (name: string): void => cookie.remove(name, cookieSetting);

class Helpers {
  getRefreshToken = (): string => getCookie(COOKIE_REFRESH_TOKEN);

  storeRefreshToken = (refreshToken: string): void => setCookie(COOKIE_REFRESH_TOKEN, refreshToken);

  getAccessToken = (): string => getCookie(COOKIE_ACCESS_TOKEN);

  storeAccessToken = (accessToken: string): void => setCookie(COOKIE_ACCESS_TOKEN, accessToken);

  clearTokens = (): void => {
    removeCookie(COOKIE_REFRESH_TOKEN);
    removeCookie(COOKIE_ACCESS_TOKEN);
  };

  getUserInfo = (): any => {
    const userInfo: any = getCookie(COOKIE_USER_INFO);
    return userInfo;
  };

  storeUserInfo = (userInfo: object): void => {
    const userInfoString = JSON.stringify(userInfo);
    setCookie(COOKIE_USER_INFO, userInfoString);
  };

  // New function to remove user information
  removeUserInfo = (): void => {
    removeCookie(COOKIE_USER_INFO);
  };
}

const instance = new Helpers();
export default instance;
