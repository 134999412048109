import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TResetPasswordParams = unknown;

export type TResetPasswordMaterials = {
  dataSubmit?: any;
};

export type TResetPasswordResponse = TCommonResponse;

export const resetPassword = async (dataSubmit: TResetPasswordMaterials): Promise<TResetPasswordResponse> => {
  try {
    const data = await dataSubmit;
    const response = await ApiService.post(`/users/api/resetPasswordByOTP/`, data);
    return response?.data;
  } catch (error) {
    message.error('API Error:', error.data.status_message);
  }
};
