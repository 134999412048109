import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getListProjectAction } from '@/redux/actions/employee';
import { getListProject, TGetListProjectResponse } from '@/services/api/employee';
import message from '@/utils/message';

// FUNCTION

export function* getListProjectSaga(action: ActionType<typeof getListProjectAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getListProject, materials);
    const getListProjectResponse: TGetListProjectResponse = response as TGetListProjectResponse;
    yield put(getListProjectAction.success(getListProjectResponse));
    successCallback?.(getListProjectResponse);
  } catch (err) {
    message.error('getListProjectSaga error:', err.data.status_message);
    yield put(getListProjectAction.failure(err));
    failedCallback?.(err);
  }
}
