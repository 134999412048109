import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TGetUserInfoParams = unknown;

export type TGetUserInfoResponse = TCommonResponse;

export const getUserInfo = async (): Promise<TGetUserInfoResponse> => {
  try {
    const response = await ApiService.get(`/users/api/getUserInfo/`);
    return response?.data; // Return the data from the response
  } catch (error) {
    message.error(error.response.data.status_message);
    throw error.response; // Rethrow the error to handle it in your saga
  }
};
