import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { postProjectSettingAction } from '@/redux/actions/project-setting';
import { postProjectSetting, TPostProjectSettingResponse } from '@/services/api/project-setting';
import message from '@/utils/message';

// FUNCTION

export function* postProjectSettingSaga(action: ActionType<typeof postProjectSettingAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(postProjectSetting, materials);
    const postProjectSettingResponse: TPostProjectSettingResponse = response as TPostProjectSettingResponse;
    yield put(postProjectSettingAction.success(postProjectSettingResponse));
    successCallback?.(postProjectSettingResponse);
  } catch (err) {
    message.error('postProjectSettingSaga error:', err.data.status_message);
    yield put(postProjectSettingAction.failure(err));
    failedCallback?.(err);
  }
}
