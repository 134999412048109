import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TSendMailParams = unknown;

export type TSendMailResponse = TCommonResponse;

export type TSendMailMaterials = {
  email?: TSendMailParams; // Correct the property name to "project_id"
};

export const sendMail = async (email): Promise<TSendMailResponse> => {
  try {
    const response = await ApiService.get(`/users/api/requestResetPasswordWithEmail/?email=${email.email}`);
    return response?.data; // Return the data from the response
  } catch (error) {
    return error; // Rethrow the error to handle it in your saga
  }
};
