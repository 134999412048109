import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateProfileEmployeeAction } from '@/redux/actions/employee';
import { updateProfileEmployee, TUpdateProfileEmployeeResponse } from '@/services/api/employee';
import message from '@/utils/message';

export function* updateProfileEmployeeSaga(action: ActionType<typeof updateProfileEmployeeAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateProfileEmployee, materials);
    const updateProfileEmployeeResponse: TUpdateProfileEmployeeResponse = response as TUpdateProfileEmployeeResponse;
    yield put(updateProfileEmployeeAction.success(updateProfileEmployeeResponse));
    successCallback?.(updateProfileEmployeeResponse);
  } catch (err) {
    message.error('updateProfileEmployeeSaga error:', err.data.status_message);
    yield put(updateProfileEmployeeAction.failure(err));
    failedCallback?.(err);
  }
}
