import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createProjectAction } from '@/redux/actions/project';
import { createProject, TCreateProjectResponse } from '@/services/api/project';
import message from '@/utils/message';

export function* createProjectSaga(action: ActionType<typeof createProjectAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createProject, materials);
    const createProjectResponse: TCreateProjectResponse = response as TCreateProjectResponse;
    yield put(createProjectAction.success(createProjectResponse));
    successCallback?.(createProjectResponse);
  } catch (err) {
    // message.error('createProjectSaga error:', err.data.status_message);
    yield put(createProjectAction.failure(err));
    failedCallback?.(err);
  }
}
