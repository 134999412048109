import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { sendEmailAction } from '@/redux/actions/company';
import { sendEmail, TSendEmailResponse } from '@/services/api/company';
import message from '@/utils/message';

// FUNCTION

export function* sendEmailSaga(action: ActionType<typeof sendEmailAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(sendEmail, materials);
    const sendEmailResponse: TSendEmailResponse = response as TSendEmailResponse;
    yield put(sendEmailAction.success(sendEmailResponse));
    successCallback?.(sendEmailResponse);
  } catch (err) {
    message.error('sendEmailSaga error:', err.data.status_message);
    yield put(sendEmailAction.failure(err));
    failedCallback?.(err);
  }
}
