import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { renameProjectAction } from '@/redux/actions/project';
import { renameProject, TRenameProjectResponse } from '@/services/api/project';
import message from '@/utils/message';

export function* renameProjectSaga(action: ActionType<typeof renameProjectAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(renameProject, materials);
    const renameProjectResponse: TRenameProjectResponse = response as TRenameProjectResponse;
    yield put(renameProjectAction.success(renameProjectResponse));
    successCallback?.(renameProjectResponse);
  } catch (err) {
    message.error('renameProjectSaga error:', err.data.status_message);
    yield put(renameProjectAction.failure(err));
    failedCallback?.(err);
  }
}
