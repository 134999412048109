import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { projectExportPdfAction } from '@/redux/actions/project-export';
import { projectExportPdf, TProjectExportPdfResponse } from '@/services/api/project-export';
import message from '@/utils/message';

// FUNCTION

export function* projectExportPdfSaga(action: ActionType<typeof projectExportPdfAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(projectExportPdf, materials);
    const projectExportPdfResponse: TProjectExportPdfResponse = response as TProjectExportPdfResponse;
    yield put(projectExportPdfAction.success(projectExportPdfResponse));
    successCallback?.(projectExportPdfResponse);
  } catch (err) {
    message.error('projectExportPdfSaga error:', err.data.status_message);
    yield put(projectExportPdfAction.failure(err));
    failedCallback?.(err);
  }
}
