import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getProjectDetailAction } from '@/redux/actions/project-detail';
import { getProjectDetail, TGetProjectDetailResponse } from '@/services/api/project-detail';
import message from '@/utils/message';

// FUNCTION

export function* getProjectDetailSaga(action: ActionType<typeof getProjectDetailAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getProjectDetail, materials);
    const getProjectDetailResponse: TGetProjectDetailResponse = response as TGetProjectDetailResponse;
    yield put(getProjectDetailAction.success(getProjectDetailResponse));
    successCallback?.(getProjectDetailResponse);
  } catch (err) {
    message.error('getProjectDetailSaga error:', err.data.status_message);
    yield put(getProjectDetailAction.failure(err));
    failedCallback?.(err);
  }
}
