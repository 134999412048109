import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { assignProjectListAction } from '@/redux/actions/employee';
import { assignProjectList, TAssignProjectListResponse } from '@/services/api/employee';
import message from '@/utils/message';

export function* assignProjectListSaga(action: ActionType<typeof assignProjectListAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(assignProjectList, materials);
    const assignProjectListResponse: TAssignProjectListResponse = response as TAssignProjectListResponse;
    yield put(assignProjectListAction.success(assignProjectListResponse));
    successCallback?.(assignProjectListResponse);
  } catch (err) {
    message.error('assignProjectListSaga error:', err.data.status_message);
    yield put(assignProjectListAction.failure(err));
    failedCallback?.(err);
  }
}
