import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { unAssignUsersListAction } from '@/redux/actions/employee';
import { unAssignUsersList, TUnAssignUsersListResponse } from '@/services/api/employee';
import message from '@/utils/message';

export function* unAssignUsersListSaga(action: ActionType<typeof unAssignUsersListAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(unAssignUsersList, materials);
    const unAssignUsersListResponse: TUnAssignUsersListResponse = response as TUnAssignUsersListResponse;
    yield put(unAssignUsersListAction.success(unAssignUsersListResponse));
    successCallback?.(unAssignUsersListResponse);
  } catch (err) {
    message.error('unAssignUsersListSaga error:', err.data.status_message);
    yield put(unAssignUsersListAction.failure(err));
    failedCallback?.(err);
  }
}
