import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TGetEmployeeInfoParams = unknown;

export type TGetEmployeeInfoResponse = TCommonResponse;

export type TGetEmployeeInfoMaterials = {
  company_id?: TGetEmployeeInfoParams;
  employee_id?: TGetEmployeeInfoParams;
};

export const getEmployeeInfo = async (params): Promise<TGetEmployeeInfoResponse> => {
  try {
    const response = await ApiService.get(
      `/companies/api/getFullEmployeeInfo/?company_id=${params.company_id}&employee_id=${params.employee_id}`,
    );
    return response?.data; // Return the data from the response
  } catch (error) {
    message.error(error.response.data.status_message);
    throw error.response; // Rethrow the error to handle it in your saga
  }
};
