import ApiService from '@/services/api';
import message from '@/utils/message';
import axios from 'axios';

// TYPES

export type TAuthLoginParams = unknown;
export type TAuthLoginBody = unknown;

export type TAuthLoginMaterials = {
  params?: TAuthLoginParams;
  body?: TAuthLoginBody;
};

export type TAuthLoginResponse = unknown;

// FUNCTION

export const authLogin = async ({ params }: TAuthLoginMaterials): Promise<TAuthLoginResponse> => {
  try {
    const response = await ApiService.post(`/users/api/loginEmail/`, { params });

    return response?.data; // Return the data from the response
  } catch (error) {
    if (error.response.data.status_message != 'Ungültiges Zugriffstoken') {
      message.error(error.response.data.status_message);
    }
    throw error.response; // Rethrow the error to handle it in your saga
  }
};
