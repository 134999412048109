import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { authLoginAction, setUser } from '@/redux/actions'; // Import userActions
import { authLogin, TAuthLoginResponse } from '@/services/api';
import Helpers from '@/services/helpers';
import message from '@/utils/message';

// FUNCTION
interface IAuthLoginResponse {
  data: any;
  access_token: string;
  refresh_token: string;
  user: any; // Replace 'any' with the actual type of your user data
  // other fields
}

export function* authLoginSaga(action: ActionType<typeof authLoginAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(authLogin, materials);
    const authLoginResponse: IAuthLoginResponse = response as IAuthLoginResponse;
    Helpers.storeAccessToken(authLoginResponse?.data.access_token);
    Helpers.storeRefreshToken(authLoginResponse?.data.refresh_token);

    // Assuming the response contains user data, extract it
    Helpers.storeUserInfo(authLoginResponse?.data.user);
    // Dispatch a Redux action to store the user data
    yield put(authLoginAction.success(authLoginResponse));

    successCallback?.(authLoginResponse);
  } catch (err) {
    yield put(authLoginAction.failure(err.data.status_message));
    failedCallback?.(err);
  }
}
