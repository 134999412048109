import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { addListEmployeeAction } from '@/redux/actions/employee';
import { addListEmployee, TAddListEmployeeResponse } from '@/services/api/employee';
import message from '@/utils/message';

export function* addListEmployeeSaga(action: ActionType<typeof addListEmployeeAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(addListEmployee, materials);
    const addListEmployeeResponse: TAddListEmployeeResponse = response as TAddListEmployeeResponse;
    yield put(addListEmployeeAction.success(addListEmployeeResponse));
    successCallback?.(addListEmployeeResponse);
  } catch (err) {
    message.error('addListEmployeeSaga error:', err.data.status_message);
    yield put(addListEmployeeAction.failure(err));
    failedCallback?.(err);
  }
}
