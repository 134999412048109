import { createActionCreator } from 'deox';

import { TGetUserInfoResponse } from '@/services/api/auth';
import message from '@/utils/message';

// CONSTANTS

export enum EGetUserInfoAction {
  GET_USER_INFO = 'GET_USER_INFO',
  GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST',
  GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS',
  GET_USER_INFO_FAILED = 'GET_USER_INFO_FAILED',
}

// TYPES

export type TGetUserInfoRequest = {
  type: EGetUserInfoAction.GET_USER_INFO_REQUEST;
  payload: {
    successCallback?: (response: TGetUserInfoResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetUserInfoSuccess = {
  type: EGetUserInfoAction.GET_USER_INFO_SUCCESS;
  payload: { response: TGetUserInfoResponse };
};

export type TGetUserInfoFailed = { type: EGetUserInfoAction.GET_USER_INFO_FAILED };

// FUNCTION

export const getUserInfoAction = {
  request: createActionCreator(
    EGetUserInfoAction.GET_USER_INFO_REQUEST,
    (resolve) =>
      (
        successCallback?: (response: TGetUserInfoResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetUserInfoRequest => {
        return resolve({ successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EGetUserInfoAction.GET_USER_INFO_SUCCESS,
    (resolve) =>
      (response: TGetUserInfoResponse): TGetUserInfoSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EGetUserInfoAction.GET_USER_INFO_FAILED,
    (resolve) =>
      (error: any): TGetUserInfoFailed => {
        message.error('GET_USER_INFO_FAILED Action:', error.data.status_message);

        return resolve({ error });
      },
  ),
};
