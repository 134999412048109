import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { assignUsersListAction } from '@/redux/actions/employee';
import { assignUsersList, TAssignUsersListResponse } from '@/services/api/employee';
import message from '@/utils/message';

export function* assignUsersListSaga(action: ActionType<typeof assignUsersListAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(assignUsersList, materials);
    const assignUsersListResponse: TAssignUsersListResponse = response as TAssignUsersListResponse;
    yield put(assignUsersListAction.success(assignUsersListResponse));
    successCallback?.(assignUsersListResponse);
  } catch (err) {
    message.error('assignUsersListSaga error:', err.data.status_message);
    yield put(assignUsersListAction.failure(err));
    failedCallback?.(err);
  }
}
