import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';
import axios from 'axios';

// TYPES

export type TSaveProjectDetailParams = unknown;

export type TSaveProjectDetailMaterials = {
  dataSubmit?: any;
};

export type TSaveProjectDetailResponse = TCommonResponse;

export const saveProjectDetail = async (
  dataSubmit: TSaveProjectDetailMaterials,
): Promise<TSaveProjectDetailResponse> => {
  try {
    const data = await dataSubmit;
    const response = await ApiService.post(`/projects/api/updateProjectWorkbook/`, data);
    return response?.data; // Return the data from the response
  } catch (error) {
    message.error(error.response.data.status_message);
    throw error.response; // Rethrow the error to handle it in your saga
  }
};
