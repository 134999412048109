import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getListEmployeeAction } from '@/redux/actions/employee';
import { getListEmployee, TGetListEmployeeResponse } from '@/services/api/employee';
import message from '@/utils/message';

export function* getListEmployeeSaga(action: ActionType<typeof getListEmployeeAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getListEmployee, materials);
    const getListEmployeeResponse: TGetListEmployeeResponse = response as TGetListEmployeeResponse;
    yield put(getListEmployeeAction.success(getListEmployeeResponse));
    successCallback?.(getListEmployeeResponse);
  } catch (err) {
    message.error('getListEmployeeSaga error:', err.data.status_message);
    yield put(getListEmployeeAction.failure(err));
    failedCallback?.(err);
  }
}
