import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';
import axios from 'axios';

// TYPES

export type TPostProjectDetailParams = unknown;

export type TPostProjectDetailMaterials = {
  params: any;
  dataSubmit?: any;
};

export type TPostProjectDetailResponse = TCommonResponse;

export const postProjectDetail = async (
  dataSubmit: TPostProjectDetailMaterials,
): Promise<TPostProjectDetailResponse> => {
  try {
    const id = await {
      'params': {
        'project_id': dataSubmit.params.project_id?.toString(),
      },
    };
    const response = await ApiService.post(`/projects/api/calculateProject/`, id);
    return response?.data; // Return the data from the response
  } catch (error) {
    // message.error('API Error:', error.data.status_message);
    return error?.response?.data; // Rethrow the error to handle it in your saga
  }
};
