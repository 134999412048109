import axios, { AxiosRequestConfig, AxiosResponse, AxiosError, AxiosInstance } from 'axios';
import { navigate } from '@reach/router';

import { LayoutPaths } from '@/pages/routers';
import { EResponseCode } from '@/common/enums';

import Helpers from './helpers';
import ApiService from './api';
import message from '@/utils/message';
import { t } from 'i18next';

type TTokenSubscribers = (error: Error | null, accessToken?: string) => void;

let isRefreshingAccessToken = false;
let tokenSubscribers: TTokenSubscribers[] = [];

const AuthorizedInstance = (baseURL: string): AxiosInstance => {
  const instance = axios.create({
    baseURL,
  });

  const refreshTokens = async (): Promise<string> => {
    const existingRefreshToken = Helpers.getRefreshToken();
    if (!existingRefreshToken) {
      throw new Error('Refresh token not found');
    }
    const response = await ApiService.post(`/users/api/generateAccessToken/`, {
      'params': { 'refresh_token': existingRefreshToken },
    });
    Helpers.storeAccessToken(response.data.data.access_token);
    return Helpers.getAccessToken();
  };

  const onRequest = async (request: AxiosRequestConfig): Promise<any> => {
    const authBearer = Helpers.getAccessToken();
    if (authBearer) {
      request.headers.Authorization = `Bearer ${authBearer}`;
    }
    return request;
  };

  const onResponseSuccess = (response: AxiosResponse): AxiosResponse => {
    const statusCode = response.data.status_code;
    const statusMessage = response.data.status_message;
    if (statusCode === 0 || statusMessage?.startsWith('Error')) {
      throw new axios.AxiosError(statusMessage, undefined, response.config, response.request, response);
    } else {
      return response;
    }
  };

  const onResponseError = async (axiosError: any): Promise<void | AxiosResponse<any>> => {
    const { response } = axiosError;
    const originalRequest = axiosError.config;
    const isNotRefreshTokenRequest = originalRequest?.url !== '/users/api/generateAccessToken/';

    if (response.status === EResponseCode.UNAUTHORIZED && originalRequest && isNotRefreshTokenRequest) {
      if (!isRefreshingAccessToken) {
        isRefreshingAccessToken = true;
        refreshTokens()
          .then((newAccessToken) => {
            onTokenRefreshed(null, newAccessToken);
          })
          .catch((err: AxiosError) => {
            onTokenRefreshed(new Error('Failed to refresh access token'));
            const refreshTokenFailed = err?.response?.config?.url === '/users/api/generateAccessToken/';
            if (refreshTokenFailed) {
              message.error(t('tryLogin'));
              Helpers.clearTokens();
              navigate(LayoutPaths.Auth);
            }
          })
          .finally(() => {
            isRefreshingAccessToken = false;
            tokenSubscribers = [];
          });
      }

      const storeOriginalRequest: Promise<void | AxiosResponse<any>> = new Promise((resolve, reject) => {
        tokenSubscribers.push((error: Error | null, newAccessToken?: string) => {
          if (error) return reject(error);

          if (originalRequest.headers) originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

          return resolve(axios(originalRequest));
        });
      });

      return storeOriginalRequest;
    }

    return Promise.reject(axiosError);
  };

  const onTokenRefreshed = (error: Error | null, newAccessToken?: string): void => {
    tokenSubscribers.map((cb: (error: Error | null, newAccessToken?: string) => void) => cb(error, newAccessToken));
  };

  instance.interceptors.request.use(onRequest, (error: AxiosError) => onResponseError(error));
  instance.interceptors.response.use(onResponseSuccess, onResponseError);
  return instance;
};

export default AuthorizedInstance;
