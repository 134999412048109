import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TGetListEmployeeParams = unknown;

export type TGetListEmployeeResponse = TCommonResponse;

export type TGetListEmployeeMaterials = {
  company_id?: TGetListEmployeeParams; // Correct the property name to "project_id"
};

export const getListEmployee = async (params): Promise<TGetListEmployeeResponse> => {
  try {
    const response = await ApiService.get(`/companies/api/getListEmployees/?company_id=${params.company_id}`);
    return response?.data; // Return the data from the response
  } catch (error) {
    message.error(error.response.data.status_message);
    throw error.response; // Rethrow the error to handle it in your saga
  }
};
