import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TUpgradeLicenseParams = unknown;

export type TUpgradeLicenseResponse = TCommonResponse;

export type TUpgradeLicenseMaterials = {
  params?: TUpgradeLicenseParams;
};

export const upgradeLicense = async ({ params }: TUpgradeLicenseMaterials): Promise<TUpgradeLicenseResponse> => {
  try {
    const response = await ApiService.post('/companies/api/freeUserUpgradeCreateCompany/', params);
    return response?.data; // Return the data from the response
  } catch (error) {
    message.error('API Error:', error.data.status_message);
  }
};
