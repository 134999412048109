import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TGetListEmployeeInsideParams = unknown;

export type TGetListEmployeeInsideResponse = TCommonResponse;

export type TGetListEmployeeInsideMaterials = {
  project_id?: TGetListEmployeeInsideParams; // Correct the property name to "project_id"
  company_id?: TGetListEmployeeInsideParams; // Correct the property name to "project_id"
};

export const getListEmployeeInside = async (params): Promise<TGetListEmployeeInsideResponse> => {
  try {
    const response = await ApiService.get(
      `/companies/api/getListEmployeesInsideProject/?company_id=${params.company_id}&project_id=${params.project_id}`,
    );
    return response?.data; // Return the data from the response
  } catch (error) {
    message.error(error.response.data.status_message);
    throw error.response; // Rethrow the error to handle it in your saga
  }
};
