import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TPostCompanySettingParams = unknown;

export type TPostCompanySettingMaterials = {
  entries(): unknown;
  dataSubmit?: any;
};

export type TPostCompanySettingResponse = TCommonResponse;

export const postCompanySetting = async (
  dataSubmit: TPostCompanySettingMaterials,
): Promise<TPostCompanySettingResponse> => {
  try {
    const data: any = await dataSubmit;
    const response = await ApiService.post(`/projects/api/updateCompanyInfoInSetting/`, data);
    return response?.data; // Return the data from the response
  } catch (error) {
    message.error(error.response.data.status_message);
    throw error.response; // Rethrow the error to handle it in your saga
  }
};
