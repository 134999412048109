import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { postCompanySettingAction } from '@/redux/actions/project-setting';
import { postCompanySetting, TPostCompanySettingResponse } from '@/services/api/project-setting';
import message from '@/utils/message';

// FUNCTION

export function* postCompanySettingSaga(action: ActionType<typeof postCompanySettingAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(postCompanySetting, materials);
    const postCompanySettingResponse: TPostCompanySettingResponse = response as TPostCompanySettingResponse;
    yield put(postCompanySettingAction.success(postCompanySettingResponse));
    successCallback?.(postCompanySettingResponse);
  } catch (err) {
    message.error('postCompanySettingSaga error:', err.data.status_message);
    yield put(postCompanySettingAction.failure(err));
    failedCallback?.(err);
  }
}
