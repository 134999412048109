import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { copyProjectAction } from '@/redux/actions/project';
import { copyProject, TCopyProjectResponse } from '@/services/api/project';
import message from '@/utils/message';

export function* copyProjectSaga(action: ActionType<typeof copyProjectAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(copyProject, materials);
    const copyProjectResponse: TCopyProjectResponse = response as TCopyProjectResponse;
    yield put(copyProjectAction.success(copyProjectResponse));
    successCallback?.(copyProjectResponse);
  } catch (err) {
    message.error('copyProjectSaga error:', err.data.status_message);
    yield put(copyProjectAction.failure(err));
    failedCallback?.(err);
  }
}
